.archive-banner {

    &__year {
        color: $primary-color;
    }

    &__end {

        &:hover {
            color: $primary-color;
        }
    }

    &__button-text {
        &:hover {
            text-shadow: 2px 2px 5px rgba(0, 0, 0, .9);
        }
    }
}

.gallery {

    &--small {
        background: $primary-color;
    }

    &__button {
        background-color: $primary-color;
        border: 3px solid $primary-color;

        &:hover {
            color: $primary-color;
        }
    }
}

.detail-banner {

    &__livestream-button--primary-accent {
        background-color: $primary-color;
        border: 3px solid $primary-color;

        &:hover {
            text-shadow: 2px 2px 5px rgba(0, 0, 0, .9);
        }
    }

    &__livestream-button--accent {
        background-color: $primary-color;
        border: 3px solid $primary-color;

        &:hover {
            text-shadow: 2px 2px 5px rgba(0, 0, 0, .9);
        }
    }

    &__ticket-button {
        background-color: $primary-color;
        border: 3px solid $primary-color;

        &:hover {
            text-shadow: 2px 2px 5px rgba(0, 0, 0, .9);
        }
    }

    &__small-text {
        color: $font-color;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, .9);
    }
}

.event-infos {

    &__button {
        background-color: $primary-color;
        border: 3px solid $primary-color;
    }

    &__social-media .svg-inline--fa {
        font-size: 2em;

        &:hover {
            color: $primary-color;
        }
    }

    &__place {
        color: $font-color;

        &:hover {
            color: $primary-color;
        }
    }

    h1 {
        border-bottom: 2px solid $primary-color;
    }
}

.event-news__date {
    color: $primary-color;
}

.headlines {
    h1 {
        border-bottom: 2px solid $primary-color;
    }

    h2 {
        border-bottom: 2px solid $primary-color;
    }

    h3 {
        border-bottom: 2px solid $primary-color;
    }
}

.detail-content {

    a {

        &:hover {
            color: $primary-color;
        }
        &.active {
            color: $primary-color;
        }
    }

    &__button {
        background-color: $primary-color;
        border: 3px solid $primary-color;
    }
}

.footer {

    &__info {

        a {
            color: $primary-color;
        }
    }

    &__item-register {
        background-color: $primary-color;
    }

    &__social-media-icon {

        a {
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.copyright {

    a {

        &:hover {
            color: $primary-color;
        }
    }
}

.main-sponsors-slider-wrapper {
    border-top: 5px solid $primary-color;
}

.event-slider {

    &__info {

        a {

            &:hover {
                color: $primary-color;
            }
        }
    }
}